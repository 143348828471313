import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [ContactComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule
  ],
  exports: [ContactComponent],
  bootstrap: [ContactComponent]
})
export class ContactModule { }
