import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent {

  public interval = 5000;
  public proportion = 100;
  
  slides = [
    {'image': 'assets/images/Technology&Service_phone_img.png'}, 
    {'image': 'assets/images/Technology&Service_phone_img2.png'}
];


}
