<div class="container-fluid" id="project-link">
	<div class="row  d-none d-md-block">
		<div class="col-12">
			<img class="img-fluid" alt="履約實績" src="assets/images/PerformanceExperience_text.png" />
		</div>
	</div>
	<div class="row  d-md-none">
		<div class="col-12">
			<img class="img-fluid" alt="履約實績" src="assets/images/PerformanceExperience_phone_text.png" />
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<ngx-light-carousel [options]="options1" [data]="products" class="wrapper c-accent"
				style="display: block; margin-bottom: 20px;">
				<section ngx-light-carousel-container class="content">
					<article ngx-light-carousel-item *ngFor="let product of products; let i = index">
						<div class="item cursor-pointer">
							<div class="product">
								<a href="{{product.url}}"><img style="border: 1px solid #ddd;border-radius: 25px;padding: 5px;margin-left: auto;
									margin-right: auto;" class="img-fluid" src="{{product.image}}"></a>
							</div>
						</div>
					</article>
					<ng-template #infiniteContainer></ng-template>
				</section>

				<ng-template #carouselContent let-product let-i="index">
					<article>
						<div class="item cursor-pointer">
							<div class="product">
								<a href="{{product.url}}"><img style="border: 1px solid #ddd;border-radius: 25px;padding: 5px;margin-left: auto;
									margin-right: auto;" class="img-fluid" src="{{product.image}}"></a>
							</div>
						</div>
					</article>
				</ng-template>

				<ng-template #carouselPrev>
					<div class="click-area">
					</div>
				</ng-template>
				<ng-template #carouselNext>
					<div class="click-area">
					</div>
				</ng-template>

				<ng-template #carouselDot let-model>
					<div class="ball bg-accent"></div>
				</ng-template>

				<ng-template #carouselProgress let-progress>
					<div class="progress"></div>
				</ng-template>

			</ngx-light-carousel>
		</div>
	</div>
</div>

