import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectComponent } from './project.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCarouselModule } from 'ngx-light-carousel'

@NgModule({
  declarations: [ProjectComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    NgxCarouselModule
  ],
  exports: [ProjectComponent],
  bootstrap: [ProjectComponent]
})
export class ProjectModule { }
