<div class="container-fluid" id="contact-link" style="position: relative;">
    <div class="row  d-none d-md-block">
        <div class="col-12">
            <img class="img-fluid" alt="關於我們" src="assets/images/AboutUs_text.png" />
        </div>
        <div class="col-12">
            <div style="position: absolute;bottom:-100px;right:25px;padding: 15px" class="map_img">
                <iframe width="550" height="400" id="gmap_canvas" allowfullscreen
                    src="https://maps.google.com/maps?q=%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E9%95%B7%E6%98%A5%E8%B7%AF328%E8%99%9F&t=&z=17&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
        </div>
    </div>
    <div class="row d-md-none">
        <div class="col-12">
            <img class="img-fluid" alt="關於我們" src="assets/images/About Us.png" />
        </div>
        <div class="col-12 text-center">
            <br><iframe
                src="https://maps.google.com/maps?q=%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E9%95%B7%E6%98%A5%E8%B7%AF328%E8%99%9F&t=&z=17&ie=UTF8&iwloc=&output=embed"
                frameborder="0" scrolling="no" marginheight="1" marginwidth="1" allowfullscreen></iframe>
        </div>
        <div class="col-12">
            <img class="img-fluid" alt="關於我們" src="assets/images/AboutUs_phone_text.png" />
        </div>
    </div>
</div>