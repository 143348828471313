import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceComponent } from './service.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCarouselModule } from '@ngmodule/material-carousel';

@NgModule({
  declarations: [ServiceComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    MatCarouselModule.forRoot()
  ],
  exports: [ServiceComponent],
  bootstrap: [ServiceComponent]
})
export class ServiceModule { }
