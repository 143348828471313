<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .spacer {
    flex: 1;
  }


  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  .example-spacer {
    flex: 1 1 auto;
  }

  .custom-toolbar {
    background-color: #396383;
    padding: 0 0px;
    height: 90px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    
  }

  .custom-toolbar a {
    font-size: 28px !important;
    color: #FFFFF4;
    font-weight: 550;
  }

  .navi li a:hover{ margin-top:-41px;}

</style>
<!-- Toolbar-->

  <mat-toolbar class="custom-toolbar">
    <img class="img-fluid" alt="極簡科技股份有限公司" src="assets/images/ximple-logo-circle.png" />
    <span class="example-spacer"></span>
    <div style="margin: 0 0px;" class="d-none d-md-block">
      <a mat-button href="#about-link"> 關於極簡 </a>
      <a mat-button href="#service-link"> 技術服務 </a>
      <a mat-button href="#project-link"> 履約實績 </a>
      <a mat-button href="#contact-link"> 聯絡我們 </a>
    </div>
    <button mat-icon-button class="d-sm-none">
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container fixedInViewport="true" autosize style="z-index: 999999;">
    <mat-sidenav #sidenav mode="over" role="navigation" position="end" fixedInViewport="true" fixedTopGap="0"
      fixedBottomGap="10">
      <mat-toolbar>
        <mat-toolbar-row>
          <h2 class="toolbar-title"> <a mat-list-item (click)="sidenav.toggle()" mat-list-item>
              <mat-icon>cancel</mat-icon>
            </a> </h2>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-list>
        <mat-list-item>
          <mat-icon>info</mat-icon>
          <a mat-list-item href="#about-link"> 關於極簡 </a>
        </mat-list-item>
        <mat-list-item>
          <mat-icon>thumb_up_alt</mat-icon>
          <a mat-list-item href="#service-link"> 技術服務 </a>
        </mat-list-item>
        <mat-list-item>
          <mat-icon>star</mat-icon>
          <a mat-list-item href="#project-link"> 履約實績 </a>
        </mat-list-item>
        <mat-list-item>
          <mat-icon>mail</mat-icon>
          <a mat-list-item href="#contact-link"> 聯絡我們 </a>
        </mat-list-item>
      </mat-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>

<app-carousel></app-carousel>

<app-about></app-about>

<app-service></app-service>

<br><br>
<app-project></app-project>

<app-contact></app-contact>

<footer>
  <div class="container-fluid" style="background-color: #396383;;padding:40px;">
    <div class="row">
      <div class="col-12">
        <ul class="align-st">
          <li><img alt="極簡科技股份有限公司" src="assets/images/ximple-logo-circle.png" />
            <h3><span class="text-white" style="padding:20px;font-size: 16pt;"> To Be Simple is Not Easy</span></h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

<ng-go-top-button [animate]=animate [speed]="speed" [acceleration]="acceleration" [scrollDistance]="scrollDistance"
  [classNames]="customClassNames">
  <mat-icon>keyboard_arrow_up</mat-icon>

</ng-go-top-button>

<!--[styles]="customStyles"-->
<!---->