<div class="container-fluid" id="about-link">
    <div class="row">
        <div class="col-md-6 d-none d-md-block">
            <img class="img-fluid" alt="關於極簡科技" src="assets/images/aboutXimple_img.png" />
        </div>
        <div class="col-md-6 d-none d-md-block">
            <img class="img-fluid" alt="關於極簡科技" src="assets/images/About Ximple_text.png" />
        </div>
    </div>
    <div class="row d-md-none">
        <div class="col-12">
            <img class="img-fluid" alt="關於極簡科技" src="assets/images/AboutXimple_phone_img.png" />
        </div>
        <div class="col-12">
            <img class="img-fluid" alt="關於極簡科技" src="assets/images/AboutXimple_phone_text.png" />
        </div>
    </div>
</div>