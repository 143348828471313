
<div class="d-none d-sm-block demo-carousel" [style.height]="parentHeight">
  <mat-carousel 
    [timings]="timings" 
    [autoplay]="autoplay" 
    [interval]="interval" 
    [loop]="loop" 
    [hideArrows]="hideArrows"
    [hideIndicators]="hideIndicators" 
    color="accent" 
    [maxWidth]="maxWidth" 
    [proportion]="proportion" 
    [useKeyboard]="useKeyboard"
    [useMouseWheel]="useMouseWheel"
    [orientation]="orientation" 
    [maintainAspectRatio]="maintainAspectRatio"
    [slideHeight]="slideHeight" 
    [slides]="slides">
      <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slidesList; let i = index" 
        [image]="slide.image"
        overlayColor="#00000040" 
        [hideOverlay]="true">
    </mat-carousel-slide>
  </mat-carousel>
</div>

<!--<slideshow [imageUrls]="imageUrlArray"></slideshow>
<div class="container">
  <carousel
  [images]="images"
  [objectFit]="'cover'"
  [cellWidth]="'100%'"
  [arrowsOutside]="false"
  [autoplay]="true"
  [dots]="true"
  [overflowCellsLimit]="1"
  [cellsToShow]="1"
  [cellsToScroll]="1"
  [loop]="true">
</carousel>
</div>
-->


