import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Orientation } from '@ngmodule/material-carousel';
import { IImage } from 'ng-simple-slideshow';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {

  
  public imageUrlArray: (string | IImage)[] = [
    { url: 'assets/images/banner-1.png', caption: 'The first slide' },
    { url: 'assets/images/banner-2.png', caption: 'Apple TV' }
  ];

  public height: string = '400px';
  minHeight: string = '30px';
  arrowSize: string = '30px';
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = true;
  autoPlayInterval: number = 3333;
  stopAutoPlayOnSlide: boolean = true;
  autoPlayWaitForLazyLoad: boolean = true;
  debug: boolean = false;
  backgroundSize: string = 'cover';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = true;
  dotColor: string = '#FFF';
  showCaptions: boolean = true;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = false;
  hideOnNoSlides: boolean = false;
  width: string = '100%';
  fullscreen: boolean = false;
  enableZoom: boolean = false;
  enablePan: boolean = false;
  noLoop: boolean = false;


/*
  public images = [
    {path: 'assets/images/banner-2.png'},
    {path: 'assets/images/banner-2.png'}
]
*/

  public slidesList = [
    {'image': 'assets/images/banner-1.png'}, 
    {'image': 'assets/images/banner-1.png'}, 
    {'image': 'assets/images/banner-1.png'}, 
    {'image': 'assets/images/banner-1.png'}, 
    {'image': 'assets/images/banner-2.png'}
  ];

  public parentHeight = 'auto';
  public timings = '250ms ease-in';
  public autoplay = true;
  public interval = 5000;
  public loop = true;
  public hideArrows = false;
  public hideIndicators = false;
  public color: ThemePalette = 'accent';
  public maxWidth = 'auto';
  public maintainAspectRatio = true;
  public proportion = 50;
  public slideHeight = '200px';
  public overlayColor = '#00000040';
  public hideOverlay = false;
  public useKeyboard = true;
  public useMouseWheel = false;
  public orientation: Orientation = 'ltr';
  public slides = this.slidesList.length;

}
