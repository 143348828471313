import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselComponent } from './carousel.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {SlideshowModule} from 'ng-simple-slideshow';

@NgModule({
  declarations: [CarouselComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    MatCarouselModule.forRoot(),
    SlideshowModule
  ],
  exports: [CarouselComponent],
  bootstrap: [CarouselComponent]
})
export class CarouselModule { }
