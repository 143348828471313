<div class="container-fluid" id="service-link">
    <div class="row">
        <div class="col-md-6 d-none d-md-block service_img_left">
            <img class="img-fluid mx-auto d-block" alt="地理資訊系統服務" src="assets/images/Technology&Service_imgLeft.png" />
        </div>
        <div class="col-md-6 d-none d-md-block service_img_right">
            <img class="img-fluid mx-auto d-block" alt="設備設施管理" src="assets/images/Technology&Service_imgRight.png" />
        </div>
    </div>
    <div class="row d-md-none">
        <div class="col-12">
            <mat-carousel timings="250ms ease-in" [autoplay]="true" [interval]="interval" color="accent" maxWidth="auto"
                [proportion]="proportion" [loop]="true" [hideArrows]="true" [hideIndicators]="false"
                [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr">
                <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
                    overlayColor="#00000040" [hideOverlay]="true"></mat-carousel-slide>
            </mat-carousel>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 d-none d-md-block text-center">
            <img class="img-fluid" alt="軟體系統研究及開發" src="assets/images/GeographicInformationSystem_img.png" />
        </div>
        <div class="col-md-6 d-none d-md-block text-center">
            <img class="img-fluid" alt="軟體系統研究及開發" src="assets/images/GeographicInformationSystem_text.png" />
        </div>
    </div>
    <div class="row d-md-none">
        <div class="col-12">
            <img class="img-fluid" alt="軟體系統研究及開發" src="assets/images/GeographicInformationSystem_phone_img.png" />
        </div>
        <div class="col-12">
            <img class="img-fluid" alt="軟體系統研究及開發" src="assets/images/GeographicInformationSystem_phone_text.png" />
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center d-none d-md-block">
            <img class="img-fluid" alt="使用者互動操作方式" src="assets/images/UserFriendly_img.png" />
        </div>
    </div>
    <div class="row d-md-none">
        <div class="col-12 text-center">
            <img class="img-fluid" alt="使用者互動操作方式" src="assets/images/UserFriendly_phone_img.png" />
        </div>
    </div>
    <div class="row  d-none d-md-block">
        <div class="col-12 text-center" style="position: relative;">
            <img class="img-fluid" alt="公司願景" src="assets/images/Future_background.png" />
            <div class="row" style="position: absolute;bottom:0px;left:0px;">
                <div class="col-12 col-md-1">
                </div>
                <div class="col-12 col-md-3">
                    <img class="img-fluid  mx-auto d-block future_img future_max" alt="持續往願景推進"
                        src="assets/images/Future_img3.png" />
                </div>
                <div class="col-12 col-md-3">
                    <img class="img-fluid  mx-auto d-block future_img future_max" alt="More Creative"
                        src="assets/images/Future_img1.png" />
                </div>
                <div class="col-12 col-md-3">
                    <img class="img-fluid  mx-auto d-block future_img future_max" alt="以合作互信為共同目標"
                        src="assets/images/Future_img2.png" />
                </div>
                <div class="col-12 col-md-1">
                </div>
            </div>
        </div>
    </div>
    <div class="row d-md-none">
        <div class="col-12 text-center">
            <img class="img-fluid" alt="使用者互動操作方式" src="assets/images/Future_phone_text.png" />
        </div>
        <div class="col-4">
            <img class="img-fluid  mx-auto d-block future_img future_max" alt="使用者互動操作方式" src="assets/images/Future_img3.png" />
        </div>
        <div class="col-4">
            <img class="img-fluid  mx-auto d-block future_img future_max" alt="使用者互動操作方式" src="assets/images/Future_img1.png" />
        </div>
        <div class="col-4">
            <img class="img-fluid  mx-auto d-block future_img future_max" alt="使用者互動操作方式" src="assets/images/Future_img2.png" />
        </div>
    </div>
</div>