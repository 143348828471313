import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent {

  title = '履約實績'
	public innerWidth: any
	products: any
	options1: any


  constructor() { 

    this.options1 = {
			animation: {
				animationClass: 'transition',
				animationTime: 500,
			},
			swipe: {
				swipeable: true,
				swipeVelocity: .004,
			},
			drag: {
				draggable: true,
				dragMany: true,
			},
      		arrows: true,
			infinite: true,
			autoplay: {
				enabled: true,
				direction: 'right',
				delay: 3000,
				stopOnHover: true,
				speed: 6000,
			},
			breakpoints: [
				{
					width: 768,
					number: 1,
				},
				{
					width: 991,
					number: 2,
				},
				{
					width: 1200,
					number: 3,
				},
				{
					width: 9999,
					number: 4,
				},
			],
		}

    this.products = []
		this.products.push({
			title: '臺北市政府警察局',
			//url: 'https://url',
			//regularPrice: '100.00',
			//salePrice: '100.00',
			image: 'assets/images/partner4.png',
		})
		this.products.push({
			title: '台北自來水',
			//url: 'https://url',
			//regularPrice: '100.00',
			//salePrice: '100.00',
			image: 'assets/images/partner2.png',
		})
		this.products.push({
			title: '台灣電力公司',
			//url: 'https://url',
			//regularPrice: '100.00',
			//salePrice: '100.00',
			image: 'assets/images/partner1.png',
		})
		this.products.push({
			title: '臺北市政府工務局新建工程處',
			//url: 'https://url',
			//regularPrice: '100.00',
			image: 'assets/images/partner5.png',
		})
		this.products.push({
			title: '行政院原子能委員會',
			//url: 'https://url',
			//regularPrice: '100.00',
			image: 'assets/images/partner3.png',
		})
    
  }



}
