import { Component, OnInit, ViewChild, ViewEncapsulation  } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent  {
  title = 'ximpleWeb';

  public animate = true;
  public speed = 50;
  public acceleration = 2;
  public scrollDistance = 500;

  public customStyles = {
    'border-radius': '20px 20px 20px 20px',
    right: '15px',
    color: 'green',
    border: '5px solid',
    'line-height': '20px'
 };

  public customClassNames = 'custom-go-top-button';

}
